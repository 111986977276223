<dpa-modal
  [modalOpen]="isOpen"
  [modalSize]="ModalSize.MD"
  (onModalClose)="onClose()"
  class="add-app-modal clr-short-modal"
>
  <dpa-modal-title class="clr-row clr-flex-items-sm-middle">
    {{ 'CUSTOM_ATTRIBUTE.SELECT_CUSTOM_ATTRIBUTE' | translate }}
  </dpa-modal-title>

  <div
    [class.multi-select]="isMultiSelect"
    class="modal-body"
  >
    <dpa-custom-attribute-identifier-selector
      [parentAttributeName]="parentAttributeName"
      [selectedIdentifier]="selectedIdentifier"
      (identifierChange)="onCustomAttributeIdentifierChange($event)"
      class="p-x4"
    />

    @if (isMultiSelect) {
      <div class="clr-row">
        <div class="clr-col-sm-6">
          @if (selectedIdentifier && customAttributeIdentifierAttributes?.length) {
            <div>{{ 'CUSTOM_ATTRIBUTE.AVAILABLE_COLUMNS_FOR_ATTRIBUTE' | translate: { identifier: selectedIdentifier } }}</div>
            <ul class="attributes-list">
              @for (column of customAttributeIdentifierAttributes; track column.name) {
                <li class="attribute">
                  {{ column.label }}

                  @if (!selectedSensorAttributesByName[column.attributeName]) {
                    <button
                      (click)="addAttribute(column)"
                      class="btn btn-sm btn-link"
                    >
                      <cds-icon shape="plus" />
                    </button>
                  } @else {
                    <button
                      (click)="removeAttribute(column)"
                      class="btn btn-sm btn-link"
                    >
                      <cds-icon
                        [attr.aria-label]="'SELECTED' | translate"
                        [title]="'SELECTED' | translate"
                        shape="check"
                        status="success"
                      />
                    </button>
                  }
                </li>
              }
            </ul>
          }
        </div>
        <div class="clr-col-sm-6">
          <div>{{ 'CUSTOM_ATTRIBUTE.SELECTED_CUSTOM_ATTRIBUTES' | translate }}</div>
          <ul class="attributes-list">
            @if (selectedCustomAttributes?.length > 0) {
              @for (column of selectedCustomAttributes; track column.name) {
                <li class="attribute">
                  {{ column.label }}
                  <button
                    (click)="removeAttribute(column)"
                    class="btn btn-sm btn-link"
                  >
                    <cds-icon
                      shape="times"
                      status="danger"
                    />
                  </button>
                </li>
              }
            } @else {
              {{ 'CUSTOM_ATTRIBUTE.NO_CUSTOM_ATTRIBUTE_SELECTED' | translate }}
            }
          </ul>
        </div>
      </div>
    } @else {
      <dpa-type-ahead
        [selectedItem]="selectedIdentifier"
        [searchableItems]="customAttributeIdentifierAttributes ?? []"
        [isSuggestionLoading]="!customAttributeIdentifierAttributes"
        [keyBy]="attributeFormatter"
        [labelFormatter]="attributeFormatter"
        [customFormatter]="attributeFormatter"
        [placeholderText]="'CUSTOM_ATTRIBUTE.SELECT_CUSTOM_ATTRIBUTE' | translate"
        (selectedItemChange)="onSelectAttribute($event)"
        class="mt-x2"
      />
    }
  </div>
  @if (isMultiSelect) {
    <div class="modal-footer">
      <button
        (click)="onClose()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        (click)="saveSensorAttributes()"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.SAVE' | translate }}
      </button>
    </div>
  }
</dpa-modal>
