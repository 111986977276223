<dpa-local-search
  #localSearch
  [showIcon]="false"
  [initValue]="getSelectedColumnFullLabel()"
  [inputValue]="searchString"
  [placeHolderText]="placeholderTextKey | translate"
  [showReset]="showReset"
  [showTooltip]="true"
  (onSearch)="setSearchString($event)"
  (onFocus)="showKeySelector($event)"
  (onClick)="showKeySelector($event)"
  (onKeydown)="onKeydownEvent($event)"
  iconClass="search"
></dpa-local-search>
<dpa-key-selector
  *ngIf="show"
  #filterKeySelector
  [show]="true"
  [showColumnsFromInput]="showColumnsFromInput"
  [allColumns]="allColumns"
  [keydownKey]="keydownKey"
  [keydownTrigger]="keydownTrigger"
  [currentKey]="getSelectedColumn()"
  [searchString]="searchString"
  [isCrossCategory]="isCrossCategory"
  [columnToggleFilterMap]="columnToggleFilterMap"
  [selectCustomAttribute]="selectCustomAttribute"
  (onDone)="onColumnChange($event)"
></dpa-key-selector>
