/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ModalSize } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { keyBy, remove } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { CustomAttributeIdentifierSelectorComponent } from '@ws1c/intelligence-core/components/query-builder/custom-attribute-identifier-selector/custom-attribute-identifier-selector.component';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store';
import { Column, ColumnIndex } from '@ws1c/intelligence-models';

/**
 * CustomAttributeSelectorComponent
 * @export
 * @class CustomAttributeSelectorComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-custom-attribute-selector',
  templateUrl: 'custom-attribute-selector.component.html',
  styleUrls: ['custom-attribute-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IntelligenceCommonModule, CustomAttributeIdentifierSelectorComponent],
})
export class CustomAttributeSelectorComponent implements OnChanges, OnInit {
  @Input() public parentAttributeName: string;
  @Input() public isMultiSelect: boolean;
  @Input() public selectedCustomAttributes: Column[] = [];

  @Output() public selectAttributes: EventEmitter<Column[]> = new EventEmitter<Column[]>();
  @Output() public selectAttribute: EventEmitter<Column> = new EventEmitter<Column>();
  @Output() public closeModal: EventEmitter<void> = new EventEmitter<void>();

  public customAttributeIdentifierAttributes: Column[];
  public selectedSensorAttributesByName: ColumnIndex = {};
  public customAttributeIdentifier$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  public selectedIdentifier: string;
  public isOpen: boolean = true;
  public readonly ModalSize = ModalSize;

  private destroyRef: DestroyRef = inject(DestroyRef);
  private changeRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private store: Store = inject(Store);

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof CustomAttributeSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedCustomAttributes?.currentValue) {
      this.selectedSensorAttributesByName = keyBy(this.selectedCustomAttributes, 'attributeName');
    }
  }

  /**
   * ngOnInit
   * @memberof CustomAttributeSelectorComponent
   */
  public ngOnInit() {
    combineLatest([
      this.customAttributeIdentifier$,
      this.store.select(IntegrationMetaSelectors.getCustomAttributeIdentifierAttributesByKey),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([_, customAttributeIdentifierAttributesByKey]: [string, Map<string, Column[]>]) => {
        if (!this.parentAttributeName || !this.selectedIdentifier) {
          return;
        }
        const key: string = `${this.parentAttributeName} - ${this.selectedIdentifier}`;
        this.customAttributeIdentifierAttributes = customAttributeIdentifierAttributesByKey?.get(key);
        this.changeRef.detectChanges();
      });
  }

  /**
   * onCustomAttributeIdentifierChange
   * @param {string} identifier
   * @memberof CustomAttributeSelectorComponent
   */
  public onCustomAttributeIdentifierChange(identifier: string) {
    this.selectedIdentifier = identifier;
    this.customAttributeIdentifier$.next(identifier);
  }

  /**
   * onSelectAttribute
   * @param {Column} column
   * @memberof CustomAttributeSelectorComponent
   */
  public onSelectAttribute(column: Column) {
    this.selectAttribute.emit(column);
    this.isOpen = false;
  }

  /**
   * addAttribute
   * @param {Column} column
   * @memberof CustomAttributeSelectorComponent
   */
  public addAttribute(column: Column) {
    this.selectedCustomAttributes.push(column);
    this.selectedSensorAttributesByName[column.attributeName] = column;
  }

  /**
   * removeAttribute
   * @param {Column} column
   * @memberof CustomAttributeSelectorComponent
   */
  public removeAttribute(column: Column) {
    remove(this.selectedCustomAttributes, column);
    this.selectedSensorAttributesByName[column.attributeName] = undefined;
  }

  /**
   * saveSensorAttributes
   * @memberof CustomAttributeSelectorComponent
   */
  public saveSensorAttributes() {
    this.selectAttributes.emit(this.selectedCustomAttributes);
    this.isOpen = false;
  }

  /**
   * attributeFormatter
   * @param {Column} column
   * @returns {string}
   * @memberof CustomAttributeSelectorComponent
   */
  public attributeFormatter(column: Column): string {
    return column.label;
  }

  /**
   * onClose
   * @memberof CustomAttributeSelectorComponent
   */
  public onClose() {
    this.closeModal.emit();
  }
}
