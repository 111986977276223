/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GenericObject, KEY_CODES, LocalSearchComponent, scrollIntoViewSticky, WindowService } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { cloneDeep, compact, filter, includes, isEmpty, isUndefined } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import {
  getColumnsByCategory,
  groupColumnsByCategory,
  sortForBaseCategory,
} from '@ws1c/intelligence-core/store/integration-meta/integration-meta-selector-helpers';
import { IntegrationMetaActions } from '@ws1c/intelligence-core/store/integration-meta/integration-meta.actions';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta/integration-meta.selectors';
import { UserPreferenceAssetsSelectors } from '@ws1c/intelligence-core/store/user-preference/user-preference-assets.selectors';
import { Category, CategoryIndex, Column, COLUMN_FILTERS, ColumnToggleFilter, DataType, EntityColumns } from '@ws1c/intelligence-models';

/**
 * Filter Key Selector Component
 * @export
 * @class KeySelectorComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-key-selector',
  templateUrl: 'key-selector.component.html',
  styleUrls: ['key-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeySelectorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('filterClassificationContainer') public filterClassificationContainer: ElementRef;
  @ViewChild('filterKeyContainer') public filterKeyContainer: ElementRef;
  @ViewChild('localSearch') public localSearch: LocalSearchComponent;

  @Input() public searchString?: string;
  @Input() public show?: boolean = false;
  @Input() public allColumns: Column[];
  @Input() public currentKey?: Column = null;
  @Input() public keydownKey?: string = null;
  @Input() public keydownTrigger?: boolean = false;
  @Input() public showSearchTextBox?: boolean = false;
  @Input() public showColumnsFromInput?: boolean = false;
  @Input() public selectedColumns?: Column[] = [];
  @Input() public isCrossCategory?: boolean = false;
  @Input() public showReset?: boolean = true;
  @Input() public selectCustomAttribute?: boolean = true;
  @Input() public columnToggleFilterMap?: Record<string, ColumnToggleFilter> = {};
  @Output() public onDone = new EventEmitter<any>();
  public FILTER_NAME = COLUMN_FILTERS;
  public filterNormalized$: Observable<boolean>;
  public filterDescriptionsUrl$: Observable<string>;
  public filterKeys: EntityColumns[] = [];
  public filterKeysClone: EntityColumns[] = [];
  public currentClassification: any;
  public hideExtraColumns = true;
  public baseCategoryResultNotFound = false;
  public baseClassificationNotFound = false; // Used when join is not enabled and no matching classification is found
  public activeCategory;
  public rawDataMap: Record<string, boolean> = {};
  public isCustomAttributeSelectorModalOpen: boolean = false;

  public isfilterKeyFocused: boolean = false;
  public resetFiltersOnClose: boolean = false;
  public keyPressed: boolean = false;
  public filterClassificationFocusedItem: GenericObject;
  public filterKeyFocusedItem: Column;
  public customAttributeName: string;
  public scrollIntoViewSticky: (parentEl: any, targetEl: any) => void = scrollIntoViewSticky;

  private lastKnownFilterKeys: EntityColumns[] = [];

  /**
   * Creates an instance of KeySelectorComponent.
   * @param {Store<CoreAppState>} store
   * @param {WindowService} windowService
   * @param {ChangeDetectorRef} cdr
   * @param {DestroyRef} destroyRef
   * @memberof KeySelectorComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    private windowService: WindowService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {
    this.filterDescriptionsUrl$ = this.store.select(UserPreferenceAssetsSelectors.getFilterDescriptionsUrl);
    this.filterNormalized$ = this.store.select(IntegrationMetaSelectors.columnFilterNormalizedEnabled);
  }

  /**
   * ngOnInit
   * @memberof KeySelectorComponent
   */
  public ngOnInit() {
    // If setting showColumnsFromInput to true, available columns are got from allColumns input.
    // showSearchTextBox is true for table attributes selection and false in filter
    // getFilterKeysByGroups$ selector, selects only columns that have filterSupported=true
    // getTableColumnsByGroups$ selector, selects all columns
    let columnsByGroups$: Observable<EntityColumns[]>;
    if (this.showColumnsFromInput) {
      columnsByGroups$ = combineLatest([
        this.store.select(IntegrationMetaSelectors.getColumnsByGroupSearchString),
        this.store.select(IntegrationMetaSelectors.getCategoriesByCategoryId),
        this.store.select(IntegrationMetaSelectors.getColumnFilterValues),
        this.store.select(IntegrationMetaSelectors.getActiveCategory),
      ]).pipe(
        takeUntilDestroyed(this.destroyRef),
        map(
          ([searchString, categoriesByCategoryId, filterValues, activeCategory]: [
            string,
            CategoryIndex,
            Record<string, boolean>,
            Category,
          ]) => {
            return this.isCrossCategory
              ? groupColumnsByCategory(this.allColumns, categoriesByCategoryId, searchString, filterValues, activeCategory)
              : getColumnsByCategory(this.allColumns, searchString, filterValues, activeCategory, categoriesByCategoryId);
          },
        ),
      );
    } else if (this.showSearchTextBox) {
      columnsByGroups$ = this.store.select(
        this.isCrossCategory ? IntegrationMetaSelectors.getTableColumnsByEntity : IntegrationMetaSelectors.getTableColumnsByGroups,
      );
    } else {
      columnsByGroups$ = this.store.select(
        this.isCrossCategory ? IntegrationMetaSelectors.getColumnsForFilter : IntegrationMetaSelectors.getFilterKeysByGroups,
      );
    }

    this.store.dispatch(IntegrationMetaActions.resetColumnsByGroupSearchString());

    columnsByGroups$.pipe(debounceTime(20), takeUntilDestroyed(this.destroyRef)).subscribe((filterKeys: EntityColumns[]) => {
      this.lastKnownFilterKeys = filterKeys;
      this.processFilterKeys();
    }),
      this.store.select(IntegrationMetaSelectors.getActiveCategory).subscribe((category: Category) => {
        this.activeCategory = category;
      });
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof KeySelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    const selectedColumnLabel = this.currentKey?.label || '';

    if (changes.columnToggleFilterMap) {
      Object.keys(this.columnToggleFilterMap ?? {}).forEach((rawAttributeName: string) => {
        this.rawDataMap[rawAttributeName] = this.currentKey?.attribute?.fullyQualifiedName === rawAttributeName;
      });
    }

    if (changes.searchString) {
      // Ignore the first change on searchString (either empty or set to selected column)
      // From the second change, start filtering based on query string and selected column label
      if (!changes.searchString.firstChange) {
        this.onSearchAvailableColumns(changes.searchString.currentValue, selectedColumnLabel);
      }

      if (changes.searchString.currentValue) {
        this.hideExtraColumns = false;
      }
    }

    if (changes.show) {
      this.processFilterKeys();
      this.getSelectedColumns().forEach((col) => {
        col.selected = false;
      });
      this.onSearchAvailableColumns(this.searchString, selectedColumnLabel);

      // columnFilters in store must be reset when hiding to avoid polluting
      // other KeySelectorComponent instances on the page
      if (!changes.show.currentValue && this.resetFiltersOnClose) {
        this.store.dispatch(IntegrationMetaActions.resetColumnFilters());
        this.resetFiltersOnClose = false;
      }
    }

    if (changes.selectedColumns) {
      this.getFilterKeys();
    }

    this.keyPressed = false;
    if (this.show) {
      this.onInputKeyDown(this.keydownKey);
    }
  }

  /**
   * ngOnDestroy
   * @memberof KeySelectorComponent
   */
  public ngOnDestroy() {
    this.store.dispatch(IntegrationMetaActions.resetColumnsByGroupSearchString());
  }

  /**
   * selectClassfication
   * @param {GenericObject} classification
   * @param {boolean} overrideCurrentKey
   * @param {boolean} resetKeyPressed
   * @memberof KeySelectorComponent
   */
  public selectClassfication(classification: GenericObject, overrideCurrentKey: boolean = false, resetKeyPressed: boolean = false) {
    let currentKeyClassification;
    if (!classification) {
      return;
    }
    currentKeyClassification =
      this.currentKey?.classification && !overrideCurrentKey
        ? this.filterKeys.filter((key) => key.label === this.currentKey?.classification.label)[0]
        : classification;
    // handle Others not specified in the classification
    if (!currentKeyClassification) {
      currentKeyClassification =
        this.currentKey?.classification && !overrideCurrentKey
          ? this.filterKeys.filter((key: EntityColumns) => key.columns.find((col) => col.categoryId === this.currentKey.categoryId))[0]
          : classification;
    }
    this.currentClassification = currentKeyClassification;
    this.filterClassificationFocusedItem = currentKeyClassification;
    this.keyPressed = resetKeyPressed ? false : this.keyPressed;
    this.filterKeyFocusedItem = this.keyPressed ? classification.columns[0] : null;
    this.isfilterKeyFocused = this.keyPressed;
    this.scrollItemIntoView(this.filterClassificationFocusedItem.columns, this.filterKeyFocusedItem, this.filterKeyContainer);
  }

  /**
   * applySelectedKey
   * @param {any} key
   * @memberof KeySelectorComponent
   */
  public applySelectedKey(key: Column) {
    this.onDone.emit(key);
  }

  /**
   * selectKeyV2
   * @param {Column} key
   * @memberof KeySelectorComponent
   */
  public selectKeyV2(key: Column) {
    if (key.dataType === DataType[DataType.STRUCTMAP] && this.selectCustomAttribute) {
      this.customAttributeName = key.attributeName;
      this.isCustomAttributeSelectorModalOpen = true;
      return;
    }
    if (!key.rawAttributeName || !this.columnToggleFilterMap[key?.rawAttributeName]) {
      this.onDone.emit(key);
      return;
    }
    if (this.rawDataMap[key.rawAttributeName]) {
      this.onDone.emit(this.columnToggleFilterMap[key.rawAttributeName]?.rawDataColumn);
    } else {
      this.onDone.emit(this.columnToggleFilterMap[key.rawAttributeName]?.normalizedColumn);
    }
  }

  /**
   * getSelectedColumns
   * @returns {any[]}
   * @memberof KeySelectorComponent
   */
  public getSelectedColumns(): any[] {
    let selectedColumns: Column[] = [];
    this.filterKeys.forEach((classification: any) => {
      selectedColumns = [...selectedColumns, ...filter(classification.columns, (c: Column) => c.selected)];
    });
    return selectedColumns;
  }

  /**
   * onSearchAvailableColumns
   * @param {string} query
   * @param {string} selected
   * @memberof KeySelectorComponent
   */
  public onSearchAvailableColumns(query: string, selected: string) {
    this.store.dispatch(IntegrationMetaActions.searchAvailableColumns({ query, selected }));
    this.isfilterKeyFocused = false;
    this.filterKeyFocusedItem = null;
    this.filterClassificationFocusedItem = null;
  }

  /**
   * toggleFilter
   * @param {string} filterName
   * @memberof KeySelectorComponent
   */
  public toggleFilter(filterName: string) {
    this.resetFiltersOnClose = true;
    this.store.dispatch(IntegrationMetaActions.toggleColumnFilter({ name: filterName }));
  }

  /**
   * preventDefault
   * @param {MouseEvent} event
   * @memberof KeySelectorComponent
   */
  public preventDefault(event: MouseEvent) {
    event.preventDefault();
  }

  /**
   * setBaseCategoryValues
   * @memberof KeySelectorComponent
   */
  public setBaseCategoryValues() {
    // Move base category columns to top
    const { entityColumnsList, baseCategoryResultsFound } = sortForBaseCategory(this.activeCategory, this.filterKeys);
    this.filterKeys = entityColumnsList;
    this.baseCategoryResultNotFound = !baseCategoryResultsFound && !!this.searchString;
    if (!baseCategoryResultsFound && !this.searchString) {
      this.baseClassificationNotFound = true;
    }
  }

  /**
   * openLink
   * @param {MouseEvent} e
   * @param {string} url
   * @memberof KeySelectorComponent
   */
  public openLink(e: MouseEvent, url: string) {
    e.preventDefault();
    this.windowService.open(url, '_blank');
  }

  /**
   * onSearchText
   * @param {string} query
   * @memberof KeySelectorComponent
   */
  public onSearchText(query: string) {
    this.onSearchAvailableColumns(query, '');
  }

  /**
   * onMenuContainerKeydown
   * @param {KeyboardEvent} event
   * @param {boolean} preventDefault
   * @memberof KeySelectorComponent
   */
  public onMenuContainerKeydown(event: KeyboardEvent, preventDefault: boolean) {
    const key = event.key;
    if (includes([KEY_CODES.UP, KEY_CODES.DOWN, KEY_CODES.LEFT, KEY_CODES.RIGHT, KEY_CODES.ENTER], key)) {
      if (preventDefault) {
        event.preventDefault();
      }
      this.onInputKeyDown(event.key);
    }
  }

  /**
   * onInputKeyDown
   * @param {number} keydownKey
   * @memberof KeySelectorComponent
   */
  public onInputKeyDown(keydownKey: string) {
    this.keyPressed = true;
    if (!this.show) {
      return;
    }
    switch (keydownKey) {
      case KEY_CODES.UP:
        this.upKeyHandler();
        break;
      case KEY_CODES.DOWN:
        this.downKeyHandler();
        break;
      case KEY_CODES.LEFT:
        this.leftKeyHandler();
        break;
      case KEY_CODES.RIGHT:
        this.rightKeyHandler();
        break;
      case KEY_CODES.ENTER:
        this.enterKeyHandler();
        break;
    }
  }

  /**
   * upKeyHandler
   * @memberof KeySelectorComponent
   */
  public upKeyHandler() {
    let displayItems: any[] = null;
    let focusedIndex: number = -1;
    if (!this.isfilterKeyFocused) {
      displayItems = this.filterKeys;
      focusedIndex = this.getFocusedItemIndex(displayItems, this.filterClassificationFocusedItem);
    } else {
      displayItems = this.filterClassificationFocusedItem.columns;
      focusedIndex = this.getFocusedItemIndex(displayItems, this.filterKeyFocusedItem);
    }
    if (focusedIndex === -1 || focusedIndex === 0) {
      this.focusItem(displayItems[displayItems.length - 1]);
    } else {
      this.focusItem(displayItems[focusedIndex - 1]);
    }
  }

  /**
   * downKeyHandler
   * @memberof KeySelectorComponent
   */
  public downKeyHandler() {
    let displayItems: any[] = null;
    let focusedIndex: number = -1;
    if (!this.isfilterKeyFocused) {
      displayItems = this.filterKeys;
      focusedIndex = this.getFocusedItemIndex(displayItems, this.filterClassificationFocusedItem);
    } else {
      displayItems = this.filterClassificationFocusedItem.columns;
      focusedIndex = this.getFocusedItemIndex(displayItems, this.filterKeyFocusedItem);
    }
    if (focusedIndex > -1 && focusedIndex < displayItems.length - 1) {
      this.focusItem(displayItems[focusedIndex + 1]);
    } else {
      this.focusItem(displayItems[0]);
    }
  }

  /**
   * leftKeyHandler
   * @memberof KeySelectorComponent
   */
  public leftKeyHandler() {
    this.filterKeyFocusedItem = null;
    this.isfilterKeyFocused = false;
  }

  /**
   * rightKeyHandler
   * @memberof KeySelectorComponent
   */
  public rightKeyHandler() {
    if (!this.isfilterKeyFocused) {
      this.selectClassfication(this.filterClassificationFocusedItem, true);
    }
  }

  /**
   * enterKeyHandler
   * @memberof KeySelectorComponent
   */
  public enterKeyHandler() {
    if (!this.isfilterKeyFocused) {
      this.selectClassfication(this.filterClassificationFocusedItem, true);
    } else {
      this.applySelectedKey(this.filterKeyFocusedItem);
    }
  }

  /**
   * getFocusedItemIndex
   * @param {GenericObject[]} group
   * @param {GenericObject} element
   * @returns {number}
   * @memberof KeySelectorComponent
   */
  public getFocusedItemIndex(group: GenericObject[], element: GenericObject): number {
    return group?.findIndex((groupItem: Column) => this.isSameItem(groupItem, element));
  }

  /**
   * isSameItem
   * @param {GenericObject} item1
   * @param {GenericObject} item2
   * @returns {boolean}
   * @memberof KeySelectorComponent
   */
  public isSameItem(item1: GenericObject, item2: GenericObject): boolean {
    if (isUndefined(item2)) {
      return item1 === item2;
    }
    return item1 && item2 && item1.columnName === item2.columnName && item1.label === item2.label && item1.groupLabel === item2.groupLabel;
  }

  /**
   * isClassificationActive
   * @param {EntityColumns} classification
   * @returns {boolean}
   * @memberof KeySelectorComponent
   */
  public isClassificationActive(classification: EntityColumns): boolean {
    return includes([this.currentKey?.entity, this.currentKey?.classification?.name], classification.name);
  }

  /**
   * focusItem
   * @param {Column} item
   * @memberof KeySelectorComponent
   */
  public focusItem(item: Column) {
    if (!this.isfilterKeyFocused) {
      this.filterClassificationFocusedItem = item;
      this.scrollItemIntoView(this.filterKeys, item, this.filterClassificationContainer);
    } else {
      this.filterKeyFocusedItem = item;
      this.scrollItemIntoView(this.filterClassificationFocusedItem.columns, item, this.filterKeyContainer);
    }
  }

  /**
   * scrollItemIntoView
   * @param {any[]} group
   * @param {any} element
   * @param {ElementRef} container
   * @memberof KeySelectorComponent
   */
  public scrollItemIntoView(group: any[], element: any, container: ElementRef) {
    if (!container) {
      return;
    }
    const focusedItemIndex: number = this.getFocusedItemIndex(group, element);
    const itemElements = container.nativeElement.getElementsByClassName('filtered-item');
    const itemElement = itemElements[focusedItemIndex];
    if (itemElement) {
      this.scrollIntoViewSticky(container.nativeElement, itemElement);
    }
  }

  /**
   * trackByEntityName
   * @param {number} _index
   * @param {EntityColumns} item
   * @returns {string}
   * @memberof KeySelectorComponent
   */
  public trackByEntityName(_index: number, item: EntityColumns): string {
    return item.name;
  }

  /**
   * trackByColumnIdFn
   * @param {number} index
   * @param {Column} item
   * @returns {string}
   * @memberof KeySelectorComponent
   */
  public trackByColumnIdFn(index: number, item: Column): string {
    return item.attribute?.fullyQualifiedName;
  }

  /**
   * toggleHideExtraColumns
   * @param {Event} event
   * @memberof KeySelectorComponent
   */
  public toggleHideExtraColumns(event: Event) {
    event.stopPropagation();
    this.hideExtraColumns = !this.hideExtraColumns;
  }

  /**
   * toggleRawData
   * @param {Event} event
   * @param {string} rawAttributeName
   * @memberof KeySelectorComponent
   */
  public toggleRawData(event: Event, rawAttributeName: string) {
    this.rawDataMap[rawAttributeName] = !this.rawDataMap[rawAttributeName];
    event.stopPropagation();
  }

  /**
   * isKeyActiveV2
   * @param {Column} column
   * @returns {boolean}
   * @memberof KeySelectorComponent
   */
  public isKeyActiveV2(column: Column): boolean {
    if (Object.keys(this.rawDataMap ?? {}).includes(this.currentKey?.attributeName)) {
      return column.rawAttributeName === this.currentKey.attribute.fullyQualifiedName;
    }
    return column.name === this.currentKey?.name;
  }

  /**
   * processFilterKeys
   * @private
   * @memberof KeySelectorComponent
   */
  private processFilterKeys() {
    if (!this.show) {
      return;
    }

    this.filterKeysClone = cloneDeep(this.lastKnownFilterKeys);
    this.getFilterKeys();
    if (this.filterKeys.length > 0) {
      this.setBaseCategoryValues();
    }
    this.cdr.detectChanges();
  }

  /**
   * getFilterKeys
   * @memberof KeySelectorComponent
   */
  private getFilterKeys() {
    if (!isEmpty(this.selectedColumns)) {
      const filterKeysToDisplay: EntityColumns[] = [];
      const selectedColumnKeys: string[] = compact(this.selectedColumns.map((column: Column) => column?.attributeName));
      this.filterKeysClone.forEach((filterKey: EntityColumns) => {
        const columns: Column[] = filterKey.columns.filter((column: Column) => !selectedColumnKeys.includes(column?.attributeName));
        if (!isEmpty(columns)) {
          filterKeysToDisplay.push(
            Object.assign(filterKey, {
              columns,
            }),
          );
        }
      });
      this.filterKeys = filterKeysToDisplay;
    } else {
      this.filterKeys = cloneDeep(this.filterKeysClone);
    }
  }
}
