<ng-container *ngLet="AUTOMATION_TYPE_KEY_MAP[activeAutomationType$ | async] as activeAutomationTypeKey">
  <dpa-modal
    *ngIf="copyAutomationDialogOpen$ | async as copyAutomationDialogOpen"
    [modalOpen]="copyAutomationDialogOpen"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'WORKFLOWS.DUPLICATE_' + activeAutomationTypeKey | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <div class="container">
        <form
          [formGroup]="automationNameForm"
          class="clr-form clr-form-horizontal form"
        >
          <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
          <div class="clr-form-control form-group">
            <label
              class="clr-control-label clr-required-mark"
              for="automationName"
            >
              {{ 'WORKFLOWS.TITLE' | translate }}
            </label>
            <div
              [class.clr-error]="!automationNameForm.controls.name.valid"
              class="clr-control-container"
            >
              <div class="clr-input-wrapper">
                <input
                  required
                  type="text"
                  class="clr-input automation-name-input"
                  id="automationName"
                  formControlName="name"
                  autofocus
                />
              </div>
              <span class="clr-subtext">
                {{ 'FORM_VALIDATION.REQUIRED_FIELD' | translate }}
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [disabled]="automationNameForm.get('name').invalid"
        (click)="confirmCopyAutomation(automationNameForm.value.name)"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.DUPLICATE' | translate }}
      </button>
    </div>
  </dpa-modal>

  <dpa-modal
    *ngIf="renameAutomationDialogOpen$ | async as renameAutomationDialogOpen"
    [modalOpen]="renameAutomationDialogOpen"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'WORKFLOWS.RENAME_' + activeAutomationTypeKey | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <div class="container">
        <form
          [formGroup]="automationNameForm"
          class="clr-form clr-form-horizontal form"
        >
          <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
          <div class="clr-form-control form-group">
            <label
              class="clr-control-label clr-required-mark"
              for="automationName"
            >
              {{ 'WORKFLOWS.TITLE' | translate }}
            </label>
            <div
              [class.clr-error]="!automationNameForm.controls.name.valid"
              class="clr-control-container"
            >
              <div class="clr-input-wrapper">
                <input
                  required
                  type="text"
                  class="clr-input automation-name-input"
                  id="automationName"
                  formControlName="name"
                  autofocus
                />
              </div>
              <span class="clr-subtext">
                {{ 'FORM_VALIDATION.REQUIRED_FIELD' | translate }}
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [disabled]="automationNameForm.get('name').invalid"
        (click)="confirmRenameAutomation(automationNameForm.value.name)"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.SAVE' | translate }}
      </button>
    </div>
  </dpa-modal>

  <dpa-modal
    *ngIf="deleteAutomationDialogOpen$ | async as deleteAutomationDialogOpen"
    [modalOpen]="deleteAutomationDialogOpen"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'WORKFLOWS.DELETE_' + activeAutomationTypeKey | translate: { name: automationName } }}
    </dpa-modal-title>
    <div class="modal-body">
      <div class="container">
        {{ 'WORKFLOWS.DELETE_' + activeAutomationTypeKey + '_WARNING' | translate }}
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        (click)="confirmDeleteAutomation()"
        class="btn btn-danger"
      >
        {{ 'COMMON_ACTIONS.DELETE' | translate }}
      </button>
    </div>
  </dpa-modal>

  <dpa-modal
    *ngIf="toggleAutomationStatusDialogOpen$ | async as toggleAutomationStatusDialogOpen"
    [modalOpen]="toggleAutomationStatusDialogOpen"
    [modalClosable]="false"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{
        (isActive ? 'AUTOMATION_ACTIONS.DISABLE_AUTOMATION' : 'AUTOMATION_ACTIONS.ENABLE_AUTOMATION') | translate: { name: automationName }
      }}
    </dpa-modal-title>
    <div class="modal-body">
      <dpa-automation-irrecoverable-actions *ngIf="countAutomationIrrecoverableActions"></dpa-automation-irrecoverable-actions>
      <ng-template [ngTemplateOutlet]="oneTimeRunText"></ng-template>
      <p>
        <ng-template [ngTemplateOutlet]="enableAutomationText"></ng-template>
        {{ (isActive ? 'AUTOMATION_ACTIONS.DISABLE_AUTOMATION_MESSAGE' : 'AUTOMATION_ACTIONS.ENABLE_AUTOMATION_MESSAGE') | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        type="button"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        (click)="toggleAutomation()"
        type="button"
        class="btn btn-primary"
      >
        {{ togglePrimaryButtonLabel | translate }}
      </button>
    </div>
  </dpa-modal>

  <dpa-modal
    *ngIf="showSaveAndEnableModal$ | async as showSaveAndEnableModal"
    [modalOpen]="showSaveAndEnableModal"
    [modalClosable]="false"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'AUTOMATION_ACTIONS.SAVE_AUTOMATION' | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <dpa-automation-irrecoverable-actions *ngIf="countAutomationIrrecoverableActions"></dpa-automation-irrecoverable-actions>
      <ng-template [ngTemplateOutlet]="oneTimeRunText"></ng-template>
      <p>
        <ng-template [ngTemplateOutlet]="enableAutomationText"></ng-template>
      </p>
    </div>
    <div class="modal-footer">
      <button
        [disabled]="isLoadingAutomationRequest$ | async"
        (click)="closeModal()"
        type="button"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [clrLoading]="isLoadingAutomationRequest$ | async"
        (click)="saveAndEnableAutomation()"
        type="button"
        class="btn btn-primary"
      >
        {{ (isOneTimeManualRunEnabled ? 'AUTOMATION_ACTIONS.SAVE_AND_RUN' : 'COMMON_MESSAGES.SAVE_AND_ENABLE') | translate }}
      </button>
    </div>
  </dpa-modal>
  <ng-template #oneTimeRunAlert>
    <clr-alert
      clrAlertType="warning"
      [clrAlertClosable]="false"
      class="pt-x4"
    >
      <clr-alert-item class="alert-item">
        <span class="alert-text">
          {{ 'AUTOMATION_ACTIONS.WILL_IMMEDIATELY_EXECUTE_ON_FILTERED_RESULTS' | translate }}
        </span>
      </clr-alert-item>
    </clr-alert>
  </ng-template>
  <ng-template #oneTimeRunText>
    <ng-container *ngIf="!isActive && (isOnlySupportsHistoricalData$ | async) === false">
      <div *ngIf="isAutomaticEvaluationType$ | async">
        {{ 'AUTOMATION_ACTIONS.SAVE_AND_ENABLE_ONE_TIME_MANUAL_RUN_MESSAGE' | translate }}
        {{ (showSaveAndEnableModal$ | async) ? ('AUTOMATION_ACTIONS.GO_BACK_AND_REFRESH_SUMMARY_PANEL' | translate) : '' }}
      </div>
      <ng-template
        *ngIf="isOneTimeManualRunEnabled"
        [ngTemplateOutlet]="oneTimeRunAlert"
      ></ng-template>
      <div class="clr-control-inline mt-x6">
        <strong>
          {{ 'AUTOMATION_ACTIONS.ONE_TIME_MANUAL_RUN' | translate }}
        </strong>
        <clr-toggle-wrapper class="ml-x1">
          <input
            clrToggle
            [checked]="isOneTimeManualRunEnabled"
            (change)="toggleOneTimeManualRun()"
            id="runAutomationToggle"
            type="checkbox"
          />
          <label for="runAutomationToggle">
            <strong>
              {{ (isOneTimeManualRunEnabled ? 'COMMON_MESSAGES.ON' : 'COMMON_MESSAGES.OFF') | translate | uppercase }}
            </strong>
          </label>
        </clr-toggle-wrapper>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #enableAutomationText>
    <ng-container *ngIf="isNewTriggersEnabled$ | async; else defaultMessage">
      <ng-container *ngIf="!isActive && (showSaveAndEnableModal$ | async)">
        {{ 'WORKFLOWS.SAVE_WORKFLOW_MESSAGE' | translate }}
      </ng-container>
    </ng-container>
    <ng-template #defaultMessage>
      <ng-container *ngIf="isInactiveInBatchMode$ | async">
        {{ 'AUTOMATION_ACTIONS.SAVE_AND_ENABLE_BATCH_MODE_MESSAGE' | translate }}
      </ng-container>
    </ng-template>
    <ng-container *ngIf="isActive">
      {{ 'AUTOMATION_ACTIONS.CONFIRM_STOP_WORKFLOW' | translate }}
    </ng-container>
  </ng-template>

  <dpa-modal
    *ngIf="runAutomationDialogOpen$ | async as runAutomationDialogOpen"
    [modalOpen]="runAutomationDialogOpen"
    [modalClosable]="false"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'AUTOMATION_ACTIONS.RUN_HEADER' | translate: { name: automationName } }}
    </dpa-modal-title>
    <div class="modal-body">
      <dpa-automation-irrecoverable-actions *ngIf="countAutomationIrrecoverableActions"></dpa-automation-irrecoverable-actions>
      <ng-template [ngTemplateOutlet]="oneTimeRunAlert"></ng-template>
      <p>
        <ng-container *ngIf="isBatchModeEnabled$ | async">
          {{ 'AUTOMATION_ACTIONS.RUN_WARNING_MESSAGE' | translate }}
        </ng-container>
        {{ 'WORKFLOWS.RUN_MESSAGE' | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        type="button"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        (click)="confirmRunAutomation()"
        type="button"
        class="btn btn-primary"
      >
        {{ 'FILTER_VALUE.YES' | translate }}
      </button>
    </div>
  </dpa-modal>

  <dpa-modal
    *ngIf="showSaveDraftModal$ | async as showSaveDraftModal"
    [modalOpen]="showSaveDraftModal"
    [modalClosable]="false"
    (onModalClose)="closeModal()"
    class="save-draft-modal"
  >
    <dpa-modal-title>
      {{ 'WORKFLOWS.ADD_' + activeAutomationTypeKey + '_NAME' | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <form
        [formGroup]="draftFormGroup"
        class="clr-form clr-form-compat"
      >
        <div class="clr-form-control">
          <dpa-inline-edit
            [autofocus]="true"
            [isInvalid]="requiredWarningVisible('name')"
            [invalidTooltip]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
            [placeholder]="'WORKFLOWS.NAME_YOUR_' + activeAutomationTypeKey | translate"
            [isRequired]="true"
            formControlName="name"
            size="10rem"
          ></dpa-inline-edit>
        </div>
        <div class="clr-form-control">
          <textarea
            [placeholder]="'COMMON_MESSAGES.ADD_DESCRIPTION_OPTIONAL' | translate"
            formControlName="description"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        type="button"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [disabled]="draftFormGroup.get('name').invalid"
        (click)="saveDraft()"
        type="button"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.SAVE' | translate }}
      </button>
    </div>
  </dpa-modal>

  <dpa-modal
    *ngIf="showEditEnabledAutomationModal$ | async as showEditEnabledAutomationModal"
    [modalOpen]="showEditEnabledAutomationModal"
    class="edit-confirmation-modal"
  >
    <dpa-modal-title>
      {{ 'WORKFLOWS.EDITING_AN_ENABLED_WORKFLOW' | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <clr-alert
        clrAlertType="warning"
        [clrAlertClosable]="false"
        class="pb-x8"
      >
        <clr-alert-item class="alert-item">
          <span class="alert-text">
            {{ 'WORKFLOWS.WORKFLOW_EDIT_CONFIRMATION' | translate }}
          </span>
        </clr-alert-item>
      </clr-alert>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        type="button"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        (click)="goToEditAutomationPage(automationDialogModel.id)"
        type="button"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.EDIT' | translate }}
      </button>
    </div>
  </dpa-modal>
</ng-container>
