/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { getColumnKey, IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { CustomAttributeIdentifierSelectorComponent } from '@ws1c/intelligence-core/components/query-builder/custom-attribute-identifier-selector/custom-attribute-identifier-selector.component';
import { FilterValueComponent } from '@ws1c/intelligence-core/components/query-builder/filter-value/filter-value.component';
import { CoreAppState, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store';
import { Category, Column, ColumnIndex, CustomAttributeFilterRule, Operator, SuggestionFilterBy } from '@ws1c/intelligence-models';

/**
 * CustomAttributeFilterGroupRuleComponent
 *
 * Terminology:
 * The attributes, which we get from /v2/meta/integration/INTEGRATION/entity/ENTITY/attributes, of data_type STRUCTMAP has to support map
 * of key-value pairs of user-defined attributes. This STRUCTMAP data_type attribute is identified as customAttribute.
 * Each customAttribute will have it's own child Rule Group to accomodate multiple user-defined attributes inside it. This child Rule
 * Group is identified as customAttributeRuleGroup.
 * The customAttributeRuleGroup will have multiple rules defining each user-defined attribute key-value pair. Each rule is
 * customAttributeFilterRule.
 *
 * @export
 * @class CustomAttributeFilterGroupRuleComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-custom-attribute-filter-group-rule',
  templateUrl: 'custom-attribute-filter-group-rule.component.html',
  styleUrls: ['custom-attribute-filter-group-rule.component.scss'],
  standalone: true,
  imports: [IntelligenceCommonModule, CustomAttributeIdentifierSelectorComponent, FilterValueComponent],
})
export class CustomAttributeFilterGroupRuleComponent implements OnInit {
  @Input() public parentFilterRule: CustomAttributeFilterRule;
  @Input() public filterRule: CustomAttributeFilterRule;
  @Input() public ruleIndex: number;
  @Input() public allColumnsByName?: ColumnIndex = {};
  @Input() public suggestionFilterBys: SuggestionFilterBy[];
  @Input() public suggestionCategory?: Category;
  @Input() public delimiterSupported?: boolean = false;
  @Output() public filterRuleChange: EventEmitter<CustomAttributeFilterRule> = new EventEmitter<CustomAttributeFilterRule>();

  public customAttributeIdentifierAttributes: Column[];
  public identifiers: string[];
  public identifierAttributes: Column[];
  public getColumnKey = getColumnKey;
  public identifier$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  private readonly store: Store<CoreAppState> = inject(Store<CoreAppState>);
  private readonly changeRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  /**
   * ngOnInit
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public ngOnInit() {
    combineLatest([this.identifier$, this.store.select(IntegrationMetaSelectors.getCustomAttributeIdentifierAttributesByKey)])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([_, identifierAttributesByKey]: [string, Map<string, Column[]>]) => {
        if (!this.parentFilterRule?.attribute || !this.filterRule.customAttributeIdentifier) {
          return;
        }
        const key: string = `${this.parentFilterRule.attribute} - ${this.filterRule.customAttributeIdentifier}`;
        this.identifierAttributes = identifierAttributesByKey?.get(key)?.filter((identifierAttribute: Column) => {
          return !identifierAttribute.isHiddenInFilter;
        });
        this.changeRef.detectChanges();
      });
  }

  /**
   * onIdentifierChange
   * @param {string} identifier
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public onIdentifierChange(identifier: string) {
    this.clearFilterRule();
    if (identifier) {
      this.filterRule.customAttributeIdentifier = identifier;
      this.identifier$.next(identifier);
    }
    this.filterRuleChange.emit(this.filterRule);
  }

  /**
   * columnFormatter
   * @param {Column} column
   * @returns {string}
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public columnFormatter(column: Column): string {
    return column?.label || '';
  }

  /**
   * onIdentifierAttributeChange
   * @param {Column} identifierAttribute
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public onIdentifierAttributeChange(identifierAttribute: Column) {
    if (identifierAttribute) {
      const blankFilterRule: CustomAttributeFilterRule = CustomAttributeFilterRule.getInitialRuleFromColumn(
        identifierAttribute,
      ) as CustomAttributeFilterRule;
      Object.assign(this.filterRule, blankFilterRule);
    } else {
      this.clearFilterRule();
    }
    this.filterRuleChange.emit(this.filterRule);
  }

  /**
   * getSelectedIdentifierAttribute
   * @returns {Column}
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public getSelectedIdentifierAttribute(): Column {
    return this.allColumnsByName[this.filterRule.attribute];
  }

  /**
   * getSelectedIdentifierAttributeOperators
   * @param {CustomAttributeFilterRule} filterRule
   * @returns {Operator[]}
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public getSelectedIdentifierAttributeOperators(filterRule: CustomAttributeFilterRule): Operator[] {
    return (this.allColumnsByName[filterRule.attribute] || ({} as Column)).supportedOperators || [];
  }

  /**
   * getOperatorKey
   * @param {Operator} operator
   * @returns {string}
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public getOperatorKey(operator: Operator): string {
    return operator?.name;
  }

  /**
   * onIdentifierAttributeOperatorChange
   * @param {Operator} identifierAttributeOperator
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public onIdentifierAttributeOperatorChange(identifierAttributeOperator: Operator) {
    const operatorValue = identifierAttributeOperator?.value;
    const isSingle: boolean = identifierAttributeOperator?.single;
    this.filterRule.valueRequired = identifierAttributeOperator?.valueRequired;
    if (this.filterRule.condition !== operatorValue) {
      this.filterRule.condition = operatorValue;
      this.filterRule.data = this.getFilterRuleData(isSingle, this.filterRule.valueRequired);
    }
    this.filterRuleChange.emit(this.filterRule);
  }

  /**
   * getSelectedIdentifierAttributeOperator
   * @param {CustomAttributeFilterRule} filterRule
   * @returns {Operator}
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public getSelectedIdentifierAttributeOperator(filterRule: CustomAttributeFilterRule): Operator {
    return this.getSelectedIdentifierAttributeOperators(filterRule).find((operator: Operator) => operator.value === filterRule.condition);
  }

  /**
   * onFilterRuleDataChange
   * @param {any} data
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  public onFilterRuleDataChange(data: any) {
    this.filterRule.data = Array.isArray(data) && !data.length ? undefined : data;
    this.filterRuleChange.emit(this.filterRule);
  }

  /**
   * clearFilterRule
   * @private
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  private clearFilterRule() {
    Object.assign(this.filterRule, {
      customAttributeIdentifier: undefined,
      condition: undefined,
      attribute: undefined,
      label: undefined,
      data: undefined,
      dataType: undefined,
    });
  }

  /**
   * getFilterRuleData
   * @private
   * @param {boolean} isSingle
   * @param {boolean} valueRequired
   * @returns {any[]}
   * @memberof CustomAttributeFilterGroupRuleComponent
   */
  private getFilterRuleData(isSingle: boolean, valueRequired: boolean): any[] {
    if (!valueRequired) {
      return null;
    }
    let data = this.filterRule.data;
    if (!data || data.length === 0) {
      return [];
    }
    data = Array.isArray(data) ? data : [data];
    return isSingle ? [data[0]] : data;
  }
}
