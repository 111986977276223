<div
  [class.with-filters]="showFilters"
  class="filter-group-date-range-container"
>
  <div
    *ngIf="showTrendDateRange"
    class="date-range-container mt-x2"
  >
    <dpa-tooltip
      [tooltipText]="'COMMON_MESSAGES.DATE_RANGE_HELP_TEXT' | translate"
      [focusEnabled]="true"
      class="mr-x1"
    >
      <cds-icon shape="calendar"></cds-icon>
    </dpa-tooltip>
    <dpa-trend-date-range
      [trendDateRange]="filterTrendDateRange"
      [readonly]="isTrendDateRangeReadonly"
      [maxCustomRange]="maxCustomRange"
      [customTimePeriodHelpText]="customTimePeriodHelpText"
      (trendDateRangeUpdated)="onDateRangeChange($event)"
    ></dpa-trend-date-range>
  </div>

  <div
    *ngIf="showFilters"
    class="filter-container pt-x1"
  >
    <dpa-filter-tags
      *ngIf="filterTags?.length"
      [hasDivider]="true"
      [filterTags]="filterTags"
      [defaultSelectedTags]="defaultSelectedTags"
      [tagsDisabled]="!filterTagsClickable"
      [class.mr-x2]="filterTagsClickable"
      (onFilterChange)="onTagFilterChange($event)"
    ></dpa-filter-tags>
    <dpa-tooltip
      *ngIf="filterTags?.length && !filterTagsClickable"
      [tooltipText]="'COMMON_MESSAGES.FILTER_TAG_DISABLE_TEXT' | translate"
      [focusEnabled]="true"
      class="is-gray mr-x2"
    >
      <cds-icon
        shape="info-circle"
        size="20"
      ></cds-icon>
    </dpa-tooltip>
    <button
      (click)="setFilterSlideOverVisible(true)"
      class="btn btn-link m-x0 p-x0"
    >
      <cds-icon
        shape="filter"
        size="18"
        class="filter-icon"
      ></cds-icon>
      {{ 'COMMON_MESSAGES.ADVANCED_FILTERS' | translate }}
      <span
        *ngIf="filterCount > 0"
        class="ml-x1 badge badge-info"
      >
        {{ filterCount }}
      </span>
    </button>
  </div>
</div>

<dpa-slide-over
  *ngIf="filterSlideOverVisible"
  [(isOpen)]="filterSlideOverVisible"
  [size]="FILTER_SLIDE_OVER_SIZE"
>
  <dpa-slide-over-header [title]="'COMMON_MESSAGES.FILTERS' | translate" />
  <dpa-filter-group-date-range
    [editable]="editable"
    [columns]="filterColumns"
    [ruleGroup]="filterRuleGroup"
    [defaultRuleGroup]="defaultRuleGroup"
    [showFilters]="showFilters"
    [showTrendDateRange]="false"
    [showThreeColumnFilter]="true"
    [isCrossCategory]="isCrossCategory"
    [suggestionFilterBys]="suggestionFilterBys"
    [showIncludesAllText]="showIncludesAllText"
    [hideEmptyRules]="true"
    (onApply)="onFilterChange($event.ruleGroup)"
  ></dpa-filter-group-date-range>
</dpa-slide-over>
