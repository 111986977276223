<div
  *ngIf="showHeader"
  class="clr-row clr-flex-items-sm-between filter-editor"
>
  <h4>
    {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS' | translate }}
  </h4>
  <span *ngIf="!readOnly">
    <button
      (click)="toggleViewMode()"
      class="btn btn-small btn-link"
    >
      {{ (viewMode ? 'COMMON_ACTIONS.EDIT' : 'COMMON_ACTIONS.CLOSE') | translate }}
    </button>
  </span>
</div>
<dpa-filter-group
  [columnsByName]="columnsByName"
  [filterColumnsByName]="filterColumnsByName || columnsByName"
  [readOnly]="viewMode"
  [group]="queryBuilder?.group"
  [isCrossCategory]="isCrossCategory"
  [delimiterSupported]="delimiterSupported"
  [showColumnsFromInput]="showColumnsFromInput"
  (rulesChanged)="onRulesChange($event)"
  (isFilterGroupValid)="isGroupValid($event)"
  class="clr-row filter-group"
></dpa-filter-group>
